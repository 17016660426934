'use client';

import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { config } from '../../common/util/config';
import styles from './page.module.scss';
import Link from 'next/link';
import dteLogo from './dteLogo.svg';
import Image from 'next/image';
import SearchBar from './search/page';
import MenuButton from './menu/page';

export default function Header(): JSX.Element {
    // Also update document title when we update header
    // document.title = `Outage Message Management | DTE Energy`;

    const classNames = ['header'];
    if (!config.isProd) {
        classNames.push('nonProd');
    }

    return (
        <header className={styles.main}>
            <div className={classNames.join(' ')}>
                <Row className="g-0">
                    <Col xs={'auto'} className="menuColumn">
                        <MenuButton />
                    </Col>
                    <Col xs={'auto'} className="logoColumn">
                        <Link href="/" className="logo">
                            <Image priority src={dteLogo} alt="DTE" />
                        </Link>
                    </Col>
                    <Col xs={'auto'}>
                        <h1>Outage</h1>
                    </Col>
                    <Col className="spacerColumn" md={'auto'}></Col>
                    <Col className="searchColumn">
                        <SearchBar />
                    </Col>
                </Row>
            </div>
        </header>
    );
}
