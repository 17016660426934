import React from 'react';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AppInsightsContext, ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { config } from '@/common/util/config';

function getConnectionString() {
    // Production
    let connectString =
        'InstrumentationKey=bb5b97dd-f3f5-4f83-be86-4af1972e54e6;IngestionEndpoint=https://centralus-0.in.applicationinsights.azure.com/;LiveEndpoint=https://centralus.livediagnostics.monitor.azure.com/';

    if (!config.isProd) {
        if (config.isUAT) {
            connectString =
                'InstrumentationKey=90d8d806-8d93-4d4d-93bb-7de96d7ea70e;IngestionEndpoint=https://eastus2-0.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus2.livediagnostics.monitor.azure.com/';
        } else if (config.isQA) {
            connectString =
                'InstrumentationKey=c42a76bc-8aa3-4ddc-8fed-87a2b3cde566;IngestionEndpoint=https://eastus2-4.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus2.livediagnostics.monitor.azure.com/';
        } else {
            connectString =
                'InstrumentationKey=2067a4a8-4b23-473b-8a30-cf446ad17116;IngestionEndpoint=https://eastus2-4.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus2.livediagnostics.monitor.azure.com/';
        }
    }

    return connectString;
}

const connectionString = getConnectionString();
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        connectionString: connectionString,
        enableAutoRouteTracking: true,
        enableAjaxErrorStatusText: true,

        extensions: [reactPlugin],
    },
});

// Turn off sending telementry for local development
if (config.isLocal) {
    appInsights.config.disableTelemetry = true;
}

appInsights.loadAppInsights();

// To instrument various React components usage tracking, apply the `withAITracking` higher-order
// component function.

const BaseAppInsightsComponent = (props: React.PropsWithChildren) => {
    return <AppInsightsContext.Provider value={reactPlugin}>{props.children}</AppInsightsContext.Provider>;
};

// TODO: may need to wrap other componetns as well?
const AppInsightsWrapper = withAITracking(reactPlugin, BaseAppInsightsComponent);

export default AppInsightsWrapper;
